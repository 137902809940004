// ** Auth Endpoints
export default {
  loginEndpoint: 'https://api-v2.laffahrestaurants.com/public/api/auth',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: 'https://api-v2.laffahrestaurants.com/public/api/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access_token',
  storageRefreshTokenKeyName: 'refresh_token',
};
